import { buildApiMutationHook } from "../_shared/helpers";

import { OpenShift } from "./types";

export const useFillOpenShiftGapsMutation = buildApiMutationHook<
  OpenShift.API.CreateBulk.QueryParams,
  OpenShift.API.CreateBulk.Response
>(OpenShift.API.CreateBulk.PATH);

export const useCreateOpenShiftMutation = buildApiMutationHook<
  OpenShift.API.Create.QueryParams,
  OpenShift.API.Create.Response
>(OpenShift.API.Create.PATH);

export const useApproveOpenShiftRequestMutation = buildApiMutationHook<
  OpenShift.API.ApproveRequest.QueryParams,
  OpenShift.API.ApproveRequest.Response
>(OpenShift.API.ApproveRequest.PATH);

export const useUpdateOpenShiftMutation = buildApiMutationHook<
  OpenShift.API.Update.QueryParams,
  OpenShift.API.Update.Response
>(OpenShift.API.Update.PATH, "patch");

export const useRejectOpenShiftRequestMutation = buildApiMutationHook<
  OpenShift.API.RejectRequest.QueryParams,
  OpenShift.API.RejectRequest.Response
>(OpenShift.API.RejectRequest.PATH);
