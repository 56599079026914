import { useCallback, useState } from "react";

import { US_MAJOR_CITY_TIMEZONES } from "@m7-health/shared-utils";
import { useQueryClient } from "@tanstack/react-query";
import { last } from "lodash";

import { Box, Grid, TextField, Typography } from "@mui/material";

import CustomInput from "~/common/components/Input";
import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { setCreateFacilityModalIsOpen } from "~/common/store";

import { useCreateFacilityMutation, useSeedFacilityMutation } from "@/api";
import { Autocomplete, MoreInfo, CustomSwitch } from "@/common/components";

export const CreateFacilityModal = () => {
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const [facilityName, setFacilityName] = useState("");
  const [facilityTimezone, setFacilityTimezone] = useState<
    (typeof US_MAJOR_CITY_TIMEZONES)[number] | undefined
  >(undefined);
  const [isSeedFacility, setIsSeedFacility] = useState(false);

  // Calculate the offset date for the test day (December 3rd, 2024)
  // Offset is 1 to 2 weeks from today (target date is the date today will mimic)
  const getTestDate = useCallback(() => {
    // Calculate days offset to make "today" November 22nd, 2024, rounded to nearest week
    const targetDate = new Date(2024, 10, 22); // November 22nd, 2024
    const today = new Date();
    // Calculate the number of days between the target date and today
    const rawDaysFromNow = Math.floor(
      (today.getTime() - targetDate.getTime()) / (1000 * 60 * 60 * 24),
    );
    const offsetInDays = Math.round(rawDaysFromNow / 7) * 7; // Round to nearest week

    const testDate = new Date(2024, 11, 3); // December 3rd, 2024
    testDate.setDate(testDate.getDate() + offsetInDays);

    return `${testDate.getFullYear()}-${String(testDate.getMonth() + 1).padStart(2, "0")}-${String(testDate.getDate()).padStart(2, "0")}`;
  }, []);

  const createFacilityModalIsOpen = useAppSelector(
    (state) => state.common.createFacilityModal.isOpen,
  );
  const onError = (error: unknown) => handleErrors(error);

  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["appConfig"] });
  };

  const { mutate: createFacility, isPending: createFacilityLoading } = useCreateFacilityMutation({
    onSuccess: () => {
      reset();
      dispatch(setCreateFacilityModalIsOpen(false));
      showSuccess("New facility created successfully");
    },
    onError,
    onSettled,
  });

  const { mutate: seedFacility, isPending: seedFacilityLoading } = useSeedFacilityMutation({
    onSuccess: () => {
      reset();
      dispatch(setCreateFacilityModalIsOpen(false));
      showSuccess(
        "New seed facility to be created queued successfully! This will be done in the background.",
      );
    },
    onError,
    onSettled,
  });

  const reset = useCallback(() => {
    setFacilityName("");
    setFacilityTimezone(undefined);
    setIsSeedFacility(false);
  }, [setFacilityName, setFacilityTimezone]);

  const onSecondaryBtnClick = useCallback(() => {
    dispatch(setCreateFacilityModalIsOpen(false));
    reset();
  }, [reset, dispatch]);

  const handleCreateFacility = useCallback(() => {
    if (!facilityName || !facilityTimezone) return;
    const payload = {
      name: facilityName,
      timezone: facilityTimezone.timezone,
    };

    if (isSeedFacility) {
      seedFacility(payload);
    } else {
      createFacility(payload);
    }
  }, [createFacility, seedFacility, facilityName, facilityTimezone, isSeedFacility]);

  const modalContent = (
    <Grid container flexDirection="column">
      <Grid item mb={2}>
        <Typography fontSize="0.875rem" sx={{ opacity: 0.6 }}>
          * Required information
        </Typography>
      </Grid>
      <Grid item>
        <CustomInput
          label="Facility name*"
          name="facilityName"
          value={facilityName}
          onChange={(event) => setFacilityName(event.target.value)}
          field={{
            value: facilityName,
            onChange: (newValue: string) => setFacilityName(newValue),
          }}
          fullWidth
        />
      </Grid>

      <Grid item mt={2}>
        <Autocomplete<(typeof US_MAJOR_CITY_TIMEZONES)[number], true>
          multiple
          trackingLabel="facilityTimezone"
          options={US_MAJOR_CITY_TIMEZONES}
          groupBy={(option) => option.state}
          getOptionLabel={(option) => `${option.city} (${option.state}) - ${option.timezone}`}
          disabled={!createFacilityModalIsOpen}
          size="small"
          value={facilityTimezone ? [facilityTimezone] : []}
          onChange={(_event, selected) => {
            const newOption = last(selected);
            if (!newOption) return;
            setFacilityTimezone(newOption);
          }}
          renderTags={([option]) => <Box ml={1}>{option?.timezone}</Box>}
          renderInput={(params) => <TextField {...params} label="Timezone*" />}
          noOptionsText="City is not listed. Please check the correct timezone on Google and select it from the dropdown."
        />
      </Grid>

      <Grid item mt={2}>
        <CustomSwitch
          switchVariant="checkbox"
          checked={isSeedFacility}
          onChange={(checked) => setIsSeedFacility(checked)}
          trackingLabel="seed-facility"
          label={
            <Box display="flex" alignItems="center">
              Create a seed facility for demos. This should only be used in sales environments.
              Timezone selected will not be used. You can only have one of these seed facilities at
              at time in this environment. The test day (originally Tuesday 2024-12-03) will appear
              in the Tuesday that is between 1 to 2 weeks from today (around {getTestDate() || ""}).
              <MoreInfo
                title={`This will create a facility with 9 units and multiple schedules, with everything populated for demo purposes (past schedules, fairness etc.). The test day (originally 2024-12-03) will appear as ${getTestDate() || ""}. Currently, you can only have one of these seed facilities at at time.`}
              />
            </Box>
          }
          name="seed-facility"
        />
      </Grid>
    </Grid>
  );

  return (
    <CustomModal
      isOpen={createFacilityModalIsOpen}
      modalContent={modalContent}
      modalHeaderText="Create a new facility"
      primaryBtnText="Create"
      onSecondaryBtnClick={onSecondaryBtnClick}
      onSubmit={handleCreateFacility}
      primaryDisabled={
        !facilityName || !facilityTimezone || createFacilityLoading || seedFacilityLoading
      }
    />
  );
};
