import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { entries, filter, intersection, keys, map, uniq, uniqBy } from "lodash";

import { ArrowLeft } from "@mui/icons-material";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListItemButton,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { IUnitBasic } from "~/routes/api/types";

import { useAppConfigQuery } from "#/features/User/queries";
import { IAppConfig } from "#/features/User/types";
import { IUnit } from "@/api";
import { useKeyBy } from "@/common/hooks";

import { Checkbox, MenuItem } from "../TrackedComponents";

import { useHandleQueryParams } from "./hooks";
import "./UnitPicker.scss";

const emptyUnitArray: IUnitBasic[] = [];
const NO_GROUP = "__NO_GROUP__";

type TGroups = { [GroupName in string]: { available: IUnit["id"][]; selected: IUnit["id"][] } };

export const UnitPicker = memo(
  ({
    onChange,
    values,
    valueIds: valueIdsFromParams,
    facilityId,
    unitOptions: availableUnitsFromParams,
    withQueryParams = false,
    showFullUnitNames = false,
  }: {
    onChange: (selectedUnits: IUnit[]) => void;
    values?: IUnit[];
    valueIds?: IUnit["id"][];
    facilityId?: string;
    unitOptions?: IUnit[] | IAppConfig["accessibleUnits"];
    withQueryParams?: boolean;
    showFullUnitNames?: boolean;
  }) => {
    // State - for groups toggle
    const [toggledGroups, setToggledGroups] = useState<Record<string, boolean> | null>(null);
    const clickedOnToggle = useRef(false);
    const facilityIdRef = useRef(facilityId);

    const valueIds = useMemo(
      () => valueIdsFromParams || map(values, "id"),
      [values, valueIdsFromParams],
    );

    // Available units and options
    const availableUnitsFromConfig = useAppConfigQuery().data?.accessibleUnits;
    const availableUnits: IUnit[] =
      (availableUnitsFromParams as IUnit[]) || availableUnitsFromConfig || emptyUnitArray;
    const options = useMemo(
      () =>
        filter(availableUnits, (unit) => !unit.archivedAt).map((unit) => ({
          label: unit.name,
          value: unit.id,
        })),
      [availableUnits],
    );

    // Build groups - If unit.config has a group name, use it, otherwise use facility name
    const groups = useMemo(
      () =>
        availableUnits.reduce((acc, unit) => {
          let groupName: string = unit.configuration.settings?.schedulerApp?.unitGroupName || "";
          groupName ||= unit.facility?.name || "";
          groupName ||= NO_GROUP;

          const group = (acc[groupName] ||= { available: [], selected: [] });
          group.available.push(unit.id);
          if (valueIds.includes(unit.id)) group.selected.push(unit.id);

          return acc;
        }, {} as TGroups),
      [availableUnits, valueIds],
    );
    // On first render, toggle first group that have one unit selected
    useEffect(() => {
      if (!!toggledGroups) return;

      const firstGroup = entries(groups).find(([_groupName, group]) => group.selected.length === 1);
      if (!firstGroup) return;

      setToggledGroups({ [firstGroup[0]]: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, !!toggledGroups]);

    // Helpers;
    const optionsByValue = useKeyBy(options, "value");
    const selectedValueIds = useMemo(
      () => intersection(valueIds, map(availableUnits, "id")),
      [availableUnits, valueIds],
    );
    const fullySelectedGroups = useMemo(
      () =>
        entries(groups).reduce((acc, [groupName, group]) => {
          if (group.available.length === group.selected.length) acc.push(groupName);

          return acc;
        }, [] as string[]),
      [groups],
    );
    const reverseGroups = entries(groups).reduce(
      (acc, [groupName, group]) => {
        group.available.forEach((unitId) => {
          acc[unitId] = groupName;
        });

        return acc;
      },
      {} as Record<IUnit["id"], string>,
    );
    const hasMoreThanOneGroup = keys(groups).length > 1;

    // Effects
    const wasSetOnceFromParamsOnRender = useHandleQueryParams({
      onChange,
      valueIds,
      withQueryParams,
      fullySelectedGroups,
      groups,
      options,
      isLoading: false,
      availableUnits,
    });
    // Reset values on facility change
    useEffect(
      () => {
        if (facilityIdRef.current !== facilityId) {
          facilityIdRef.current = facilityId;
          wasSetOnceFromParamsOnRender.current && onChange([]);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [facilityId],
    );

    // Actions
    const onUnitSelect = useCallback(
      (event: SelectChangeEvent<unknown>) => {
        const newSelectedUnitIds = event.target.value as (typeof options)[number]["value"];

        onChange(availableUnits.filter((unit) => newSelectedUnitIds.includes(unit.id)));
      },
      [availableUnits, onChange],
    );

    const onGroupSelect = useCallback(
      (groupSelection: IUnit["id"][], allSelected: boolean) => {
        const updatedIdSelection = allSelected
          ? selectedValueIds.filter((unitId) => !groupSelection.includes(unitId))
          : uniq([...selectedValueIds, ...groupSelection]);

        onChange(availableUnits.filter((unit) => updatedIdSelection.includes(unit.id)));
      },
      [availableUnits, onChange, selectedValueIds],
    );

    // Renderers
    const buildGroupElement = useCallback(
      (groupName: string, group: { available: IUnit["id"][]; selected: IUnit["id"][] }) => {
        if (!hasMoreThanOneGroup) return null;

        const allSelected = group.selected.length === group.available.length;
        const indeterminate = group.selected.length > 0 && !allSelected;
        const toggledClass = toggledGroups?.[groupName] ? "toggled" : "";

        return (
          <ListSubheader
            className="li-group-selector"
            onClickCapture={() => {
              // Set timeout as this very clickCapture is triggered first - before click on toggle group.
              // So we wait, and execute after potential toggle group click and the `clickedOnToggle` ref is set.
              setTimeout(() => {
                if (clickedOnToggle.current) return (clickedOnToggle.current = false);

                onGroupSelect(group.available, allSelected);
              });
            }}
          >
            <Checkbox
              indeterminate={indeterminate}
              checked={allSelected}
              trackingLabel="select-unit"
            />
            <ListItemText primary={groupName} />
            <ListItemButton
              className="toggle-group-button"
              onClick={() => {
                clickedOnToggle.current = true;
                setToggledGroups((prev) => ({
                  ...prev,
                  [groupName]: !prev?.[groupName],
                }));
              }}
            >
              <ArrowLeft className={`toggle-group-icon ${toggledClass}`} />
            </ListItemButton>
          </ListSubheader>
        );
      },
      [hasMoreThanOneGroup, onGroupSelect, toggledGroups],
    );

    const renderSelectedValues = useCallback(
      (selected: IUnit["id"][]) => {
        // If showFullUnitNames, show all units, else show groups
        let labels: string[] = [];

        if (showFullUnitNames) {
          labels = selected.map((value) => optionsByValue[value]?.label || "");
        } else {
          // For each selected item, select the group
          // If there is only one available group, show units
          const items = uniqBy(
            selected.map((value) => {
              const itemGroup = reverseGroups[value]!;

              return hasMoreThanOneGroup
                ? { group: itemGroup }
                : fullySelectedGroups.includes(itemGroup)
                  ? { unit: "All" }
                  : { unit: optionsByValue[value]?.label || "" };
            }),
            (item) => item.group || item.unit,
          );

          labels = items.map(({ group, unit }) =>
            group ? `(${groups[group]?.selected.length || 0}) ${group}` : unit || "",
          );
        }

        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }} className="selected-values">
            {labels.map((label) => (
              <Chip key={label} label={label} />
            ))}
          </Box>
        );
      },
      [
        fullySelectedGroups,
        groups,
        hasMoreThanOneGroup,
        optionsByValue,
        reverseGroups,
        showFullUnitNames,
      ],
    );

    return (
      <FormControl className="m7-unit-group-picker" sx={{ m: 1, width: 300 }}>
        <InputLabel id="unit-selector-label">Units</InputLabel>
        <Select
          labelId="unit-selector-label"
          id="unit-selector"
          MenuProps={{ className: "m7-unit-group-picker-menu" }}
          multiple
          value={selectedValueIds}
          onChange={onUnitSelect}
          input={<OutlinedInput label="Units" />}
          renderValue={renderSelectedValues}
        >
          {entries(groups).map(([groupName, group]) => {
            const groupElement = buildGroupElement(groupName, group);

            return [
              groupElement,
              // Map over all options, but only show the ones that are part of the group
              options.map(({ value, label }) => {
                if (!group.available.includes(value)) return null;

                const hidden = hasMoreThanOneGroup && !toggledGroups?.[groupName];

                return (
                  <MenuItem
                    trackingLabel={null}
                    key={value}
                    value={value}
                    sx={{
                      ...(hasMoreThanOneGroup ? { pl: 4 } : {}),
                      ...(hidden ? { display: "none" } : {}),
                    }}
                  >
                    <Checkbox
                      checked={selectedValueIds.indexOf(value) > -1}
                      trackingLabel="select-unit-group"
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                );
              }),
            ];
          })}
        </Select>
      </FormControl>
    );
  },
);
