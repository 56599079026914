import { ReactNode, useMemo } from "react";
import { Id, toast, ToastOptions } from "react-toastify";

import Alert, { AlertColor } from "@mui/material/Alert";

import CustomButton from "../components/TrackedComponents/Button";

const options = {
  position: "bottom-center",
  hideProgressBar: true,
  closeButton: false,
  draggable: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
} satisfies ToastOptions;

type onClose = (id: Id) => void | undefined;

const buildToastedAlert = (severity: AlertColor, onClose?: onClose) => {
  return (
    message: string | ReactNode,
    actionOrOptions?: (() => void) | (ToastOptions & { action?: () => void; actionLabel?: string }),
    legacyActionLabel?: string,
  ) => {
    const action =
      typeof actionOrOptions === "function" ? actionOrOptions : actionOrOptions?.action;
    const actionLabel =
      typeof actionOrOptions === "function" ? legacyActionLabel : actionOrOptions?.actionLabel;
    const toastOptions = typeof actionOrOptions === "object" ? actionOrOptions : {};

    const actionButton = action ? (
      <CustomButton
        color="inherit"
        size="small"
        onClick={action}
        trackingLabel={actionLabel}
        variant="text"
      >
        {actionLabel || "View"}
      </CustomButton>
    ) : null;

    const currentToast = toast(
      <div>
        <Alert severity={severity} action={actionButton} onClose={() => null}>
          {replaceNewLinesWithBr(message)}
        </Alert>
      </div>,
      {
        ...options,
        ...toastOptions,
        onClose: () => {
          onClose?.(currentToast);
        },
      },
    );

    return {
      id: currentToast,
      update: (newMessage: string) => {
        toast.update(currentToast, {
          render: (
            <div>
              <Alert severity={severity} action={actionButton} onClose={() => null}>
                {replaceNewLinesWithBr(newMessage)}
              </Alert>
            </div>
          ),
          ...options,
        });
      },
    };
  };
};

export type TToast = ReturnType<ReturnType<typeof buildToastedAlert>>;
export type TToaster = ReturnType<typeof buildToastedAlert>;

const replaceNewLinesWithBr = (message: string | ReactNode) =>
  typeof message === "string"
    ? message
        .split("\n")
        .reduce(
          (acc, line) => (acc.length ? [...acc, <br />, line] : [line]),
          [] as (string | JSX.Element)[],
        )
    : message;

const toasters = (onClose?: onClose) => ({
  showSuccess: buildToastedAlert("success", onClose),
  showInfo: buildToastedAlert("info", onClose),
  showWarning: buildToastedAlert("warning", onClose),
  showError: buildToastedAlert("error", onClose),
});

export const useToast = ({ onClose }: { onClose?: onClose } | undefined = {}) => {
  return useMemo(() => toasters(onClose), [onClose]);
};
