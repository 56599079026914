import { buildApiMutationHook } from "../_shared/helpers";

import { Unit } from "./types";

export const useUpdateUnitMutation = buildApiMutationHook<
  Unit.API.Update.QueryParams,
  Unit.API.Update.Response
>(Unit.API.Update.PATH, "patch");

export const useArchiveUnitMutation = buildApiMutationHook<
  Unit.API.Archive.QueryParams,
  Unit.API.Archive.Response
>(Unit.API.Archive.PATH, "patch");
