import { axiosInstance } from "~/common/packages/httpClient";
import { IStaff } from "~/features/Roster/types";
import { IAppConfig, IStaffPreferences, IUser, IUserEmailUpdate } from "~/features/User/types";

export const getCurrentUser = async () => {
  const { data } = await axiosInstance.get<IUser>("/users/current");
  return data;
};

export const getAppConfig = async (): Promise<IAppConfig> => {
  const { data } = await axiosInstance.get<IAppConfig>("/users/current/get-app-configuration");

  return data;
};

export const getStaffDetails = async () => {
  const { data } = await axiosInstance.get<IStaff>("/staff/profile");

  return data;
};

export const updateStaffPreferences = async (body: IStaffPreferences) => {
  const { data } = await axiosInstance.patch<void>("/staff/profile", body);

  return data;
};

export const updateUserEmail = async (body: IUserEmailUpdate) => {
  const { data } = await axiosInstance.patch<IUserEmailUpdate>("/users/email/update", body);

  return data;
};
