import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { getTzDayjs, m7DayJs } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Box, CircularProgress, Grid } from "@mui/material";

import { useInvalidateQuery } from "~/api/_shared/hooks";
import { useAppDispatch, useAppSelector, useM7SimpleContext, useToast } from "~/common/hooks";
import { useErrors } from "~/common/hooks/useErrors";
import { editUserApi } from "~/features/Roster/api";
import { setIsEditStaffModalOpen } from "~/features/Roster/store";

import { useUserQuery } from "#/features/Roster/queries";
import { editRequestBody } from "#/features/Roster/StaffRoster/editRequestBody";
import {
  useListPreferenceRequirementRuleSetsQuery,
  useListPreferencesTemplatesQuery,
  useListStaffDetailsQuery,
  User,
} from "@/api";

import { CustomButton } from "../../TrackedComponents";

import { initialState } from "./initialState";
import StaffDataForm from "./StaffDataForm";
import { useStaffDataForm } from "./StaffDataForm/useStaffDataForm";

export const StaffUserModal = ({ id }: { id: string }) => {
  /** HIGH LEVEL STATE */
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateQuery();
  const { currentTimezone } = useM7SimpleContext();

  const selectedUnit = useAppSelector((state) => state.common.currentUnit);
  const { data: usersData = initialState, isFetching } = useUserQuery(id, true);

  const staffFormMethods = useStaffDataForm(selectedUnit?.id || "", true);
  const {
    getValues: getStaffFormData,
    formState: { isValid: isStaffFormValid },
    reset: resetStaffForm,
    setError: setStaffErrors,
  } = staffFormMethods;

  useEffect(() => {
    resetStaffForm({
      ...usersData,
      roleTypes: usersData.roles,
      contractEndDate: usersData.contractEndDate ? m7DayJs(usersData.contractEndDate) : null,
      employmentStartDate: usersData.employmentStartDate
        ? getTzDayjs(usersData.employmentStartDate, currentTimezone)
        : null,
      orientationEndDate: usersData.orientationEndDate
        ? getTzDayjs(usersData.orientationEndDate, currentTimezone)
        : null,
      unitAssignment: usersData.staffUnitAssignment,
      userRole: User.ERole.staff,
    });
  }, [resetStaffForm, usersData, currentTimezone]);

  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["roster"] });
    void invalidateQueries(useListStaffDetailsQuery);
    void invalidateQueries(useListPreferenceRequirementRuleSetsQuery);
    void invalidateQueries(useListPreferencesTemplatesQuery);
  };

  const { mutate: editUser, isPending: isEditUserPending } = useMutation({
    mutationFn: editUserApi,
    onError: (error) => {
      handleErrors(error, setStaffErrors);
    },
    onSuccess: () => {
      showSuccess("User updated successfully.");
      dispatch(setIsEditStaffModalOpen(false));
      invalidateQueries(useListStaffDetailsQuery);
    },
    onSettled,
  });

  const handleFormSubmit = () => {
    if (!isStaffFormValid) return;

    const body = editRequestBody(true, getStaffFormData);
    editUser(body);
    return;
  };

  const loadingContent = (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );

  if (isFetching) {
    return loadingContent;
  }

  return (
    <Grid container minHeight="100vh" sx={{ position: "relative", pb: 8 }}>
      <FormProvider {...staffFormMethods}>
        <StaffDataForm userData={usersData} />

        <Grid
          item
          xs={12}
          // floating button container
          sx={{
            position: "fixed",
            bottom: 0,
            width: "600px",
            right: 0,
            padding: 2,
            zIndex: 1,
            bgcolor: "background.paper",
            borderTop: 1,
            borderLeft: 2,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              variant="contained"
              onClick={handleFormSubmit}
              disabled={!isStaffFormValid || isEditUserPending}
              children={isEditUserPending ? "Saving..." : "Save Changes"}
            />
          </Box>
        </Grid>
      </FormProvider>
    </Grid>
  );
};
