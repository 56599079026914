import { useMemo } from "react";

import { EUnitPermissionAreas, YyyyMmDd } from "@m7-health/shared-utils";
import { keyBy, map } from "lodash";

import { Article, PersonAdd } from "@mui/icons-material";
import { Box, lighten, Tooltip, Typography } from "@mui/material";

import {
  INote,
  IScheduleShiftType,
  IStaffDetails,
  IStaffShift,
  IStaffType,
  StaffDetails,
  StaffShift,
} from "~/api";
import { CompactFloatedTag } from "~/common/components/CompactFloatedTag/CompactFloatedTag";
import { NoteUpdateTag } from "~/common/components/NoteUpdateTag/NoteUpdateTag";
import Radio from "~/common/components/TrackedComponents/Radio";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import {
  black,
  brandPurple,
  darkGray,
  darkPurple,
  disabledGray,
  lightGray,
} from "~/common/theming/colors";
import { emptySx, KeyBy, TSx } from "~/common/types";
import { compactDate, timeAdd, TimeStringToStandardTime } from "~/common/utils/dates";
import { useAppConfigQuery } from "~/features/User/queries";
import { IUnitBasic } from "~/routes/api/types";

import { AttributeTag, ShiftV2 } from "@/common/components";
import { useAppFlags, useCheckUserPermission, useCurrentRole, useKeyBy } from "@/common/hooks";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { houseViewStore } from "../store";

export const UnitCardStaff = ({
  sx = emptySx,
  unitId,
  notes,
  notesFromStaff,
  staffDatesMetadata,
  staffDetails,
  shift,
  shiftType,
  variant,
  compactShiftTag,
  withOtherShifts = false,
  highlightIfEligible = false,
  selectableToFloat = false,
  showNotes = false,
}: {
  shiftType?: IScheduleShiftType;
  unitId: IUnitBasic["id"];
  shift: IStaffShift;
  staffDetails: KeyBy<IStaffDetails, "userId">;
  staffDatesMetadata?: KeyBy<StaffDetails.ShiftMetadataDTO, "staffId">;
  notes: KeyBy<INote, "userId">;
  notesFromStaff: KeyBy<INote, "userId">;
  variant?: "compact";
  compactShiftTag?: boolean;
  withOtherShifts?: boolean;
  highlightIfEligible?: boolean;
  selectableToFloat?: boolean;
  sx?: TSx;
  showNotes?: boolean;
}) => {
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const { hvPositionAsTab } = useAppFlags();

  const unitsById = keyBy(useAppConfigQuery().data?.accessibleUnits || [], "id");
  const { id, staffId } = shift;
  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
  const floatingInProgress = useAppSelector(
    (state) => state.houseView.sidebarCurrentAction.inProgress === "floating",
  );
  const selectedStaffId = useAppSelector((state) => state.houseView.floating?.staffShift?.staffId);
  const selectedStaffCategory = useAppSelector(
    (state) => state.houseView.pageFilters.selectedStaffCategory,
  );
  const dispatch = useAppDispatch();

  const unit = unitsById[unitId];
  const unitAttributesByKey = useMemo(
    () =>
      keyBy(
        (unit?.attributes || []).filter((attr) => attr.usedForShiftOverview),
        "key",
      ),
    [unit],
  );

  const details = staffDetails[staffId];
  const selectedUnitAttributes = useAppConfigQuery().data?.accessibleUnits?.find(
    ({ id: aUnitId }) => aUnitId === selectedUnitId,
  )?.attributes;
  const unitPositions = useKeyBy(selectedUnitAttributes, "key");
  const staffPositions = useMemo(
    () => map(details?.attributeKeys, (key) => unitPositions[key]?.name.toLocaleLowerCase()),
    [details, unitPositions],
  );

  if (!shift || !details) return null;

  const {
    user: { firstName, lastName, rosters },
    staffType: { name: staffTypeName, key: staffTypeKey, staffCategoryKey },
    homeUnitId,
  } = details;
  const unitIds = map(rosters, "unitId");

  const highlightIfEligibleMode = highlightIfEligible && selectedUnitId !== unitId;
  const note = notes[staffId];
  const noteFromStaff = notesFromStaff[staffId];
  const noteContent = note?.content;
  const noteFromStaffContent = noteFromStaff?.content;
  const voidedShiftStatus = voidingShiftStatus(shift) && shift.status;
  const nonVoidedShiftStatus = !voidingShiftStatus(shift) && shift.status;
  const status = voidedShiftStatus || nonVoidedShiftStatus;
  const positionEligible =
    hvPositionAsTab && selectedStaffCategory
      ? staffPositions?.includes(selectedStaffCategory)
      : false;

  highlightIfEligible &&=
    !!selectedUnitId &&
    !!floatingInProgress &&
    selectedUnitId !== unitId &&
    !voidedShiftStatus &&
    (selectedStaffCategory === staffCategoryKey || positionEligible) &&
    unitIds.includes(selectedUnitId);

  const opacitySx =
    voidedShiftStatus || (floatingInProgress && !highlightIfEligible && selectedUnitId !== unitId)
      ? { opacity: 0.3 }
      : {};
  const compact = variant === "compact";
  const shiftSx = (compactShiftTag && {
    height: "20px",
    maxWidth: "40px",
    padding: "0 !important",
    width: "22px",
    ".MuiButton-startIcon": {
      marginRight: "-3px",
    },
  }) || {
    width: "110px",
    pl: "10px !important",
    justifyContent: "flex-start",
  };
  const fontSize = compact ? "0.8rem" : undefined;

  if (
    !shiftType || // we hide no shift
    (!shiftType.isCountedForRealTimeStaffingTarget && !withOtherShifts) || // we hide not counted for real time staffing target, except if with other shifts
    staffTypeKey === ("houseSupervisor" as IStaffType["key"]) // we hide house supervisor
  )
    return null;

  const selectShift = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    dispatch(houseViewStore.state.setFloatShift({ id, staffId }));
  };

  const lastFloatedAt = staffDatesMetadata?.[staffId]?.lastFloatedAt as YyyyMmDd | undefined;
  const compactLastFloatedAt = lastFloatedAt ? compactDate(lastFloatedAt) : undefined;
  const isFloated = shift.status === StaffShift.EStatus.floated;

  return (
    <Box
      key={id}
      display={"flex"}
      minWidth={"250px"}
      px={0.5}
      mt={0.5}
      flexWrap={"wrap"}
      flexDirection={"column"}
      className={"unit-card-staff-container"}
      sx={{
        boxSizing: "border-box",
        borderRadius: "5px",
        ...(highlightIfEligibleMode
          ? {}
          : {
              "&:nth-of-type(odd)": {
                backgroundColor: lightGray,
              },
            }),
        ...(highlightIfEligible ? { backgroundColor: lighten(brandPurple, 0.9) } : {}),
        ...(!userIsKiosk &&
        canManage &&
        highlightIfEligible &&
        !selectableToFloat &&
        selectedStaffId !== staffId
          ? {
              "&:hover": {
                border: `2px solid ${lighten(brandPurple, 0.3)}`,
              },
            }
          : {}),
        ...(selectedStaffId === staffId
          ? {
              border: `2px solid ${darkPurple}`,
              backgroundColor: lighten(darkPurple, 0.9) + " !important",
            }
          : { border: `2px solid transparent` }),
        ...(!userIsKiosk && canManage && (selectableToFloat || highlightIfEligible)
          ? { cursor: "pointer" }
          : {}),
        ...sx,
      }}
      onClick={
        !userIsKiosk && canManage && (selectableToFloat || highlightIfEligible)
          ? selectShift
          : undefined
      }
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        maxWidth={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {selectableToFloat && (
          <Radio
            sx={{ pl: 0, ...(compact ? { p: 0, pr: 1 } : {}) }}
            onChange={selectShift}
            checked={selectableToFloat && selectedStaffId === staffId}
            trackingLabel="Select staff to float"
          />
        )}
        <Box sx={!compactShiftTag ? { width: "100px" } : {}}>
          <ShiftV2
            shiftType={shiftType}
            variant={compactShiftTag || compact ? "small" : "medium"}
            miniView={compactShiftTag}
            sx={{ mr: 1, ...opacitySx, ...shiftSx }}
          />
        </Box>
        <Typography sx={opacitySx} fontSize={fontSize} whiteSpace={"nowrap"}>
          <span style={{ fontWeight: 500 }}>
            {lastName} {firstName}
          </span>
          , <span style={{ fontSize: "12px" }}>{staffTypeName}</span>
        </Typography>
        {shift.attributes?.map((attr) => {
          const attributeToShow = unitAttributesByKey[attr];
          if (!attributeToShow) return <></>;
          return <AttributeTag sx={opacitySx} attribute={attributeToShow} />;
        })}
        {/** handle edge case where someone's home unit changes
         *  by only showing floated tag if home unit different and if floated that day */}
        {(unitId !== homeUnitId || isFloated) && !selectableToFloat && (
          <CompactFloatedTag
            sx={{ ml: 1 }}
            unitId={homeUnitId}
            isFloatedStatus={isFloated}
            grayIfNotFloatedStatus={true}
          />
        )}
        {status && (voidedShiftStatus || (nonVoidedShiftStatus && !isFloated)) && (
          <NoteUpdateTag
            update={status}
            sx={{ ml: 1, opacity: 0.7 }}
            variant="compact"
            unitId={unitId}
          />
        )}
        {showNotes && (noteContent || noteFromStaffContent) && (
          <Tooltip
            sx={opacitySx}
            placement="top"
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {`${noteFromStaffContent ? `Note from ${firstName}: ${noteFromStaffContent}` : ""}${
                  noteFromStaffContent && noteContent ? "\n\n" : ""
                }${noteContent ? `Note to ${firstName}: ${noteContent}` : ""}`}
              </div>
            }
            enterTouchDelay={0}
          >
            <Article sx={{ ml: 1, color: disabledGray, "&:hover": { color: black } }} />
          </Tooltip>
        )}
        <Box flexGrow={1} />
        <Typography ml={1} sx={opacitySx} fontSize={fontSize} whiteSpace={"nowrap"}>
          {TimeStringToStandardTime(shift.customStartTime || shiftType.startTime, "medium")} -{" "}
          {TimeStringToStandardTime(
            timeAdd(
              shift.customStartTime || shiftType.startTime,
              shift.customDuration || shiftType.durationSeconds,
            ),
            "medium",
          )}
        </Typography>
      </Box>
      {(highlightIfEligible || selectableToFloat) && (
        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
          <Typography
            py={highlightIfEligible ? 0.5 : 0}
            fontSize={"0.8rem"}
            sx={selectableToFloat ? mainViewSx : {}}
          >
            <i style={{ color: darkGray }}>Last Floated: </i>
            {compactLastFloatedAt ? (
              <b>{compactLastFloatedAt}</b>
            ) : (
              <i style={{ color: darkGray }}>N/A</i>
            )}
          </Typography>
          <Box flexGrow={1} />
          {selectableToFloat && (
            <Typography color={darkGray} fontWeight="500" fontSize={"13px"} pl={1}>
              {" "}
              {unitsById[homeUnitId]?.name}
            </Typography>
          )}
          {highlightIfEligible && (
            <Typography
              borderRadius={2}
              variant="small"
              fontSize={13}
              sx={{
                backgroundColor: brandPurple,
                display: "flex",
                alignItems: "center",
                height: "10px",
                padding: "5px",
                borderRadius: "5px",
                ml: 1,
                opacity: 0.8,
              }}
            >
              <PersonAdd fontSize="small" />
              Eligible
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const mainViewSx = {};

export const __HouseViewUnitCardStaff = UnitCardStaff;
