import { useMemo } from "react";

import { useCurrentFacilityId, useFilterBy, useMap } from "~/common/hooks";
import { useCustomSelectShiftsForUnit } from "~/common/hooks/useShiftsForUnit";
import { Uuid } from "~/common/types";
import { IStaffAttribute, IUnitCustomSelect } from "~/routes/api/types";
import { useAllStaffAttributesQuery } from "~/routes/queries";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  useListPreferenceRequirementRuleSetsQuery,
  useListPreferencesTemplatesQuery,
  useListSchedulersQuery,
} from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";

const emptyStaffAttributes: IStaffAttribute[] = [];

/**
 * Custom hook to fetch and memoize data required for the Staff Data Form.
 *
 * @param {Uuid | undefined} homeUnitId - The ID of the home unit, or undefined if not available.
 * @returns {object} An object containing:
 * - units: Array of units fetched from the app config accessible units.
 * - schedulerData: Data of schedulers fetched from the useListSchedulersQuery hook.
 * - allStaffAttributes: Array of all staff attributes fetched from the useAllStaffAttributesQuery hook.
 * - shiftOptions: Options for shifts fetched from the useCustomSelectShiftsForUnit hook.
 * - ruleSets: Rule sets fetched from the useListPreferenceRequirementRuleSetsQuery hook.
 * - preferencesTemplates: Preferences templates fetched from the useListPreferencesTemplatesQuery hook.
 * - staffTypes: Staff types fetched from the useAppConfigQuery hook.
 */
export const useStaffDataFormQueries = (homeUnitId: Uuid | undefined) => {
  const facilityId = useCurrentFacilityId();

  const unitsDTOs = useAppConfigQuery().data?.accessibleUnits;
  const filteredUnits = useFilterBy(unitsDTOs, (unit) => unit.facilityId === facilityId, [
    facilityId,
  ]);
  const units = useMap(filteredUnits, (unit) => {
    return {
      ...unit,
      value: unit.id,
      label: unit.name + (unit.archivedAt ? "(archived)" : ""),
    } as IUnitCustomSelect;
  });

  const { data: schedulerData } = useListSchedulersQuery(
    {
      facilityId: facilityId || NOT_EXISTING_UUID,
    },
    { skip: !facilityId },
  );
  const { data: allStaffAttributes = emptyStaffAttributes } = useAllStaffAttributesQuery();

  const shiftOptions = useCustomSelectShiftsForUnit();

  const { data: ruleSets } = useListPreferenceRequirementRuleSetsQuery(
    { unitIds: [homeUnitId || NOT_EXISTING_UUID] },
    { skip: !homeUnitId },
  );

  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    { unitIds: [homeUnitId || NOT_EXISTING_UUID] },
    { skip: !homeUnitId },
  );

  const staffTypes = useAppConfigQuery().data?.staffTypes;

  return useMemo(() => {
    return {
      units,
      schedulerData,
      allStaffAttributes,
      shiftOptions,
      ruleSets,
      preferencesTemplates,
      staffTypes,
    };
  }, [
    units,
    schedulerData,
    allStaffAttributes,
    shiftOptions,
    ruleSets,
    preferencesTemplates,
    staffTypes,
  ]);
};
