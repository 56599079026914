import { Fragment, useMemo } from "react";

import { DateCmp, ISODateString, m7DayJs, YyyyMmDd } from "@m7-health/shared-utils";
import { capitalize, kebabCase, keyBy, map, uniq } from "lodash";

import { Box, darken, lighten, Tooltip, Typography } from "@mui/material";

import { IShiftType, IStaffShift, IUser, StaffShift } from "~/api";
import { FLOATING_ICON, iconComponentForShift } from "~/common/constants";
import { Dayjs, localDayJs } from "~/common/packages/dayjs";
import { floatedStaffColor, mediumGray, multipleShiftsColor, white } from "~/common/theming/colors";
import { AnyDate } from "~/common/types";
import { getDateInUtcIsoFormat } from "~/common/utils/dates";

import { CalendarStyled } from "#/features/CalendarV2/components/Calendar/Calendar.styled";

import { StaffCalendarHooks } from "./hooks";

const {
  useCalendarDays,
  useStaffShifts,
  useSchedulesShiftTypes,
  useStaffUnitShiftTypes,
  usePreferences,
} = StaffCalendarHooks;

const StaffCalendar = ({
  staffId,
  weeksAround = 1,
  onDayClick,
  selectedDate,
  showPreferences = true,
  showShifts = true,
}: {
  staffId: IUser["id"];
  weeksAround?: number;
  onDayClick?: (day: Dayjs, event: React.MouseEvent) => void;
  selectedDate: AnyDate;
  showPreferences?: boolean;
  showNoteUpdates?: boolean;
  showShifts?: boolean;
}) => {
  // build an array containing days around the selected date
  const calendarDays = useCalendarDays({ selectedDay: selectedDate, weeksAround });

  // Retrieve data of the staff for those dates
  const shifts = useStaffShifts(
    staffId,
    {
      from: calendarDays[0]?.toISOString() as ISODateString,
      to: calendarDays[calendarDays.length - 1]?.toISOString() as ISODateString,
    },
    !showShifts,
  );
  const scheduleIds = useMemo(() => uniq(map(shifts, "scheduleId")), [shifts]);
  const shiftTypesByScheduleIdByKey = useSchedulesShiftTypes(scheduleIds);
  const unitShiftTypesByKey = useStaffUnitShiftTypes(staffId);

  const staffPreferences = usePreferences(
    staffId,
    {
      from: getDateInUtcIsoFormat(calendarDays[0] || m7DayJs()) as YyyyMmDd,
      to: getDateInUtcIsoFormat(calendarDays[calendarDays.length - 1] || m7DayJs()) as YyyyMmDd,
    },
    !showPreferences,
  );

  const shiftsByDay = useMemo(() => {
    const sortShiftsByStartTime = (shiftA: IStaffShift, shiftB: IStaffShift) => {
      // Order shifts by start time so earlier shifts are before later shifts
      const timeA =
        shiftTypesByScheduleIdByKey?.[shiftA.scheduleId]?.[shiftA.shiftTypeKey]?.startTime || "";
      const timeB =
        shiftTypesByScheduleIdByKey?.[shiftB.scheduleId]?.[shiftB.shiftTypeKey]?.startTime || "";
      if (timeA < timeB) return -1;
      if (timeA > timeB) return 1;
      return 0;
    };
    return shifts?.reduce(
      (acc, curr) => {
        const formattedDate = getDateInUtcIsoFormat(curr.date) as YyyyMmDd;
        const shiftsForDay = acc[formattedDate];
        if (!shiftsForDay) {
          acc[formattedDate] = [curr];
        } else {
          shiftsForDay.push(curr);
          acc[formattedDate] = shiftsForDay.sort(sortShiftsByStartTime);
        }
        return acc;
      },
      {} as Record<YyyyMmDd, IStaffShift[]>,
    );
  }, [shifts, shiftTypesByScheduleIdByKey]);
  const preferenceByDay = useMemo(() => keyBy(staffPreferences || [], "date"), [staffPreferences]);

  /**************************************** */
  /* Getters for the data of a specific day */
  /**************************************** */
  const getDayClasses = (day: Dayjs) => {
    const selectedDayJs = m7DayJs(selectedDate);
    const formattedDay = getDateInUtcIsoFormat(day) as YyyyMmDd;
    const dayClasses = ["calendar-day tile"];
    const daysShiftsAndTypes = getShiftsAndTypes(day);
    const hasMultipleShifts = daysShiftsAndTypes && daysShiftsAndTypes.length > 1;
    const hasShiftOnDay = daysShiftsAndTypes?.some(([shift, _type]) => !!shift);
    const hasPartialShift = daysShiftsAndTypes?.some(([shift, _type]) => shift?.customDuration);

    if (day.isSame(localDayJs(), "day")) dayClasses.push("today");
    if (DateCmp.isEqual(day, selectedDayJs)) dayClasses.push("highlighted-day");

    if (hasShiftOnDay) dayClasses.push("with-shift");
    if (hasPartialShift) dayClasses.push("partial-shift");

    const firstStatus = shiftsByDay?.[formattedDay]?.find((shift) => shift?.status)?.status;
    const shiftStatus = firstStatus;

    if (shiftStatus && !hasMultipleShifts) {
      dayClasses.push("with-note-update");
      dayClasses.push(kebabCase(shiftStatus));
    }

    return dayClasses;
  };

  const getShiftsAndTypes = (day: Dayjs) => {
    const formattedDate = getDateInUtcIsoFormat(day) as YyyyMmDd;
    const shiftsOnDay = shiftsByDay?.[formattedDate];
    const shiftsWithType = shiftsOnDay?.map((shift) => {
      const shiftTypeKey = shift?.shiftTypeKey;
      let shiftType: IShiftType | undefined;
      if (shiftTypeKey) {
        shiftType = shiftTypesByScheduleIdByKey[shift.scheduleId]?.[shiftTypeKey];
        shiftType ||= unitShiftTypesByKey?.[shiftTypeKey];
      }
      return [shift, shiftType];
    });

    return shiftsWithType as [IStaffShift, IShiftType | undefined][];
  };

  const getShiftIconAndBg = (day: Dayjs) => {
    let Icon: React.FC | null = null;
    let backgroundColor = null;

    const daysShiftsAndTypes = getShiftsAndTypes(day);
    const hasMultipleShifts = daysShiftsAndTypes && daysShiftsAndTypes.length > 1;
    const firstShift = daysShiftsAndTypes?.[0]?.[0];
    const firstShiftType = daysShiftsAndTypes?.[0]?.[1];

    if (hasMultipleShifts) {
      Icon = iconComponentForShift("Multi");
      backgroundColor = multipleShiftsColor;
    } else if (firstShift?.isWorkingAway) {
      Icon = FLOATING_ICON;
      backgroundColor = floatedStaffColor;
    } else {
      // Default Icon if no shift type is found
      if (firstShiftType) Icon = iconComponentForShift(firstShiftType?.muiIconClassName);
      if (firstShiftType) backgroundColor = firstShiftType.scheduleViewColor.trim();
    }

    return {
      icon: Icon && <Icon />,
      backgroundColor,
    };
  };

  const getPreferenceIconAndBg = (day: Dayjs) => {
    let Icon: React.FC | null = null;
    let backgroundColor = null;

    const preference = preferenceByDay[getDateInUtcIsoFormat(day)];
    if (!preference) return { icon: null, backgroundColor: null };

    const shiftType = unitShiftTypesByKey[preference.shiftTypeKey];
    if (!shiftType) return { icon: null, backgroundColor: null };

    Icon = iconComponentForShift(shiftType?.muiIconClassName);
    backgroundColor = shiftType.scheduleViewColor.trim();

    return {
      icon: Icon && <Icon />,
      backgroundColor,
    };
  };

  //Gets the label to display when hovering over a day
  //shows hours for working shifts and statuses
  const getDaysHoverLabel = (day: Dayjs) => {
    const daysShiftsAndTypes = getShiftsAndTypes(day);
    let workingDuration = 0;
    let statusString = "";
    const timeByStatus = {} as Record<StaffShift.EStatus, number>;

    daysShiftsAndTypes?.forEach(([shift, shiftType]) => {
      if ((!shift.status || shift.isWorkingAway) && shiftType?.isWorkingShift) {
        workingDuration += shift.customDuration ?? shiftType.durationSeconds;
      } else if (shift.status && !shift.isWorkingAway) {
        const duration = shift.customDuration ?? shiftType?.durationSeconds ?? 0;
        timeByStatus[shift.status] = (timeByStatus[shift.status] ?? 0) + duration;
      }
    });

    for (const status in timeByStatus) {
      statusString += `${capitalize(status)}: ${Number.parseFloat((timeByStatus[status as StaffShift.EStatus] / 3600).toFixed(2))}hrs\n`;
    }
    const workingString =
      workingDuration > 0
        ? `Working: ${Number.parseFloat((workingDuration / 3600).toFixed(2))}hrs\n`
        : "";
    return workingString + statusString;
  };

  return (
    <CalendarStyled style={{ paddingBottom: 0 }}>
      <Box className="grid v2">
        {calendarDays.map((day, index) => {
          const isFirstElement = index === 0;
          const isFirstRow = index < 7;

          const formattedDay = day.format("D");
          const { icon, backgroundColor } = getShiftIconAndBg(day);
          const { icon: preferenceIcon, backgroundColor: preferenceBackgroundColor } =
            getPreferenceIconAndBg(day);
          const hoverLabel = getDaysHoverLabel(day);

          return (
            <Fragment key={day.valueOf()}>
              <Box
                className={getDayClasses(day).join(" ")}
                gridColumn={`${day.day() + 1}/${day.day() + 2}`}
                onClick={(event) => onDayClick?.(day, event)} // Here, we are passing the reference to the function directly
                data-day={day.toISOString()}
                sx={{
                  borderColor: !backgroundColor ? mediumGray : darken(backgroundColor, 0.1),
                  backgroundColor: !backgroundColor ? "transparent" : lighten(backgroundColor, 0.3),
                  borderWidth: backgroundColor ? "2px" : "1px",
                  cursor: "default",
                }}
              >
                {/* Show the month if it's the first element of the schedule of if that's the first day of a month */}
                {(isFirstElement || formattedDay === "1") && (
                  <Typography className="month-in-day-name">{day.format("MMM")}</Typography>
                )}
                {/* Show the week day */}
                {isFirstRow && (
                  <Typography className="day-of-week-in-day-name">{day.format("dd")}</Typography>
                )}
                {preferenceIcon && (
                  <Box
                    className="preference-container"
                    sx={{ backgroundColor: preferenceBackgroundColor }}
                  >
                    {preferenceIcon}
                  </Box>
                )}

                <Tooltip
                  placement="bottom"
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: { offset: [0, -14] },
                        },
                      ],
                    },
                  }}
                  title={
                    hoverLabel ? (
                      <Typography sx={{ fontSize: "15px", color: white, whiteSpace: "pre-line" }}>
                        {hoverLabel}
                      </Typography>
                    ) : null
                  }
                >
                  <Box className="tile-content" zIndex={1}>
                    <Typography
                      sx={{ fontSize: "0.875rem", fontWeight: 500, color: "inherit" }}
                      className="day-text"
                    >
                      {formattedDay}
                    </Typography>
                    {icon && <Box className="icon-container">{icon}</Box>}
                    <Box className="note-update-container" />
                  </Box>
                </Tooltip>
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </CalendarStyled>
  );
};

export default StaffCalendar;
