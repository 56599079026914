import { Controller } from "react-hook-form";

import upperFirst from "lodash/upperFirst";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";

import { darkGray, disabledGrey } from "~/common/theming/colors";

import { MenuItem } from "../TrackedComponents";

import { ICustomInputControlledProps, ICustomInputProps } from "./types";

export const CustomInputControlled = ({
  control,
  name,
  type,
  ...rest
}: ICustomInputControlledProps) => {
  // todo: fix me. Default Value is not propagated to the input
  return control ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange } = field;

        // nothing is checking if the value is null, so we need to set it to empty string... because CustomInput should always have a string value
        // TODO: add more type checking to CustomInput
        if (!field.value) {
          field.value = "";
        }

        return (
          <CustomInput
            name={name}
            field={field}
            onChange={(event) => {
              const val = event.target.value;
              // Inputs with type number had values converted to strings when typed manually,
              // so we want to convert them back to numbers
              const fixedTypeValue = type === "number" ? Number(val) : val;
              return onChange(fixedTypeValue);
            }}
            {...rest}
          />
        );
      }}
    />
  ) : (
    <Controller
      name={name}
      render={({ field }) => {
        const { onChange } = field;

        return (
          <CustomInput
            name={name}
            field={field}
            onChange={(event) => {
              const val = event.target.value;
              // Inputs with type number had values converted to strings when typed manually,
              // so we want to convert them back to numbers
              const fixedTypeValue = type === "number" ? Number(val) : val;
              return onChange(fixedTypeValue);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};

export const CustomInput = ({
  label,
  type = "text",
  field,
  disabled,
  errors,
  maxNumber,
  name,
  items,
  readOnly,
  select,
  counter = false,
  fullWidth = true,
  rows,
  maxLength,
  multiline = false,
  dontShowNegative,
  ...rest
}: ICustomInputProps) => {
  const errorMessage = upperFirst((errors?.[name]?.message as string) ?? "");
  /* eslint-disable
  @typescript-eslint/no-unsafe-argument,
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-member-access
*/
  const displayValue = Number(field.value) < 0 && dontShowNegative ? "" : field?.value?.toString();
  /* eslint-enable
  @typescript-eslint/no-unsafe-argument,
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-member-access
*/
  const decrement = () => {
    const fieldValue = Number(field.value || 0);

    if (fieldValue === 0) {
      return;
    }
    field.onChange?.(fieldValue - 1);
  };

  const increment = () => {
    const fieldValue = Number(field.value || 0);
    if (maxNumber === undefined || maxNumber > fieldValue) {
      field.onChange?.(fieldValue + 1);
    }
  };

  return (
    <TextField
      size="small"
      id={typeof label === "string" ? label : undefined}
      label={label}
      variant="outlined"
      type={type}
      fullWidth={fullWidth}
      select={select}
      error={!!errorMessage}
      helperText={errorMessage}
      rows={rows}
      multiline={multiline}
      // need to convert to string to remove leading zeroes, does not do well trying to render a number
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      value={displayValue}
      // cannot have {...field}, messes the above above with the field.value.toString()
      inputProps={{
        maxLength,
      }}
      InputProps={{
        disabled,
        endAdornment: counter ? (
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            width="60px"
            flexWrap="nowrap"
          >
            <RemoveIcon
              sx={{
                color: disabled ? disabledGrey : darkGray,
                cursor: disabled ? "auto" : "pointer",
              }}
              onClick={!readOnly && !disabled ? decrement : undefined}
            />
            <AddIcon
              sx={{
                color: disabled ? disabledGrey : darkGray,
                cursor: disabled ? "auto" : "pointer",
              }}
              onClick={!readOnly && !disabled ? increment : undefined}
            />
          </Grid>
        ) : null,
        readOnly,
      }}
      {...rest}
    >
      {select && items
        ? items.map((item) => (
            <MenuItem key={item.value} value={item.value} trackingLabel={null}>
              {item.label}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
};
