import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box, darken, Stack, Typography } from "@mui/material";

import { black, darkPurple, lavender, lightGray } from "~/common/theming/colors";
import { pxToRem } from "~/common/utils/pxToRem";

import { Link } from "..";

import { ESelectedFileVariant, SelectedFileProps } from "./types";

const getFontColorForTheVariant = (variant: ESelectedFileVariant) => {
  switch (variant) {
    case ESelectedFileVariant.default:
      return black;
    case ESelectedFileVariant.highlighted:
      return darkPurple;
  }
};

const getBackgroundColorForTheVariant = (variant: ESelectedFileVariant) => {
  switch (variant) {
    case ESelectedFileVariant.default:
      return lightGray;
    case ESelectedFileVariant.highlighted:
      return lavender;
  }
};

export const SelectedFile = ({
  variant = ESelectedFileVariant.default,
  fileName,
  path,
}: SelectedFileProps) => {
  return (
    <Box
      display="inline-block"
      paddingY="9px"
      paddingX="12px"
      borderRadius={1}
      sx={[
        {
          cursor: "pointer",
          backgroundColor: getBackgroundColorForTheVariant(variant),
        },
        {
          "&:hover": {
            backgroundColor: darken(getBackgroundColorForTheVariant(variant), 0.1),
          },
        },
      ]}
    >
      <Link href={path} download={fileName} trackingLabel={null}>
        <Stack direction="row" gap={1} alignItems="center">
          <InsertDriveFileOutlinedIcon sx={{ color: getFontColorForTheVariant(variant) }} />
          <Typography
            variant="body2"
            color={getFontColorForTheVariant(variant)}
            fontWeight={500}
            lineHeight={pxToRem(18)}
          >
            {fileName}
          </Typography>
        </Stack>
      </Link>
    </Box>
  );
};
