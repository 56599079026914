import { memo, useCallback, useMemo, useState } from "react";

import { get } from "lodash";

import { Stack } from "@mui/material";

import { CustomButton, CustomSelect } from "@/common/components";

import { IUserWithDetails, TEditableColumns, TOnEditUser, TValueForColumn } from "../types";

import { EditableComponentsByType } from "./Cell";
import { TCellComponent } from "./cells";

const emptyFunction = () => ({});

export const BulkUpdateField = memo(
  ({
    selectedUsers,
    onEditUsers,
  }: {
    selectedUsers: IUserWithDetails[];
    onEditUsers: TOnEditUser<TEditableColumns>;
  }) => {
    const [selectedField, setSelectedField] = useState<TEditableColumns>("staffDetails.shiftType");
    const [selectedValue, setSelectedValue] = useState<TValueForColumn<TEditableColumns> | null>(
      null,
    );

    const handleApply = useCallback(() => {
      onEditUsers(selectedUsers, selectedField, selectedValue);
      setSelectedValue(null);
    }, [onEditUsers, selectedField, selectedValue, selectedUsers]);

    return (
      <Stack direction="row" spacing={2} alignItems="flex-end" className="bulk-update-field">
        <CustomSelect
          className="column-selector"
          items={updateFieldOptions}
          label="Update Field"
          onChange={(event) => setSelectedField(event.target.value as TEditableColumns)}
          value={selectedField}
          variant="standard"
        />

        <BulkEditableField
          onChange={(value) => setSelectedValue(value)}
          attribute={selectedField}
          value={selectedValue}
        />
        <CustomButton
          trackingLabel={"bulk-apply-data"}
          label={`Apply (${selectedUsers.length})`}
          onClick={handleApply}
          disabled={!selectedUsers.length}
          sx={{ px: 3 }}
        />
      </Stack>
    );
  },
);

const BulkEditableField = memo(
  <T extends TEditableColumns>({
    attribute,
    onChange,
    value,
  }: {
    attribute: T;
    onChange: (value: TValueForColumn<T> | null) => void;
    value: TValueForColumn<T> | null;
  }) => {
    const Component = (
      get(EditableComponentsByType, attribute) as TCellComponent<TValueForColumn<T>> | undefined
    )?.editor;

    const params = useMemo(
      () => ({
        value: value,
        onChange,
        onBlur: emptyFunction,
        triggerEditMode: emptyFunction,
        autoFocus: false,
      }),
      [onChange, value],
    );

    if (!Component) return <></>;

    return <Component {...params} />;
  },
);

const updateFieldOptions: { label: string; value: TEditableColumns }[] = [
  { label: "Shift Type", value: "staffDetails.shiftType" },
  { label: "Staff Type", value: "staffDetails.staffTypeKey" },
  { label: "Float Eligibility", value: "user.unitIds" },
  { label: "Requirements", value: "staffDetails.preferenceRequirementRuleSetIds" },
  { label: "Preference Template", value: "staffDetails.preferencesTemplateId" },
  { label: "Employment Type", value: "staffDetails.employmentType" },
  { label: "Preceptor", value: "staffDetails.preceptor" },
  { label: "On Orientation", value: "staffDetails.onOrientation" },
  { label: "Orientation End Date", value: "staffDetails.orientationEndDate" },
  { label: "Position Eligibility", value: "staffDetails.attributeKeys" },
];
