import { axiosInstance } from "~/common/packages/httpClient";
import { TGetSchedulesForChargeShifts } from "~/features/Fairness/ChargeShifts/types";
import { TGetSchedulesForExperiencePerShift } from "~/features/Fairness/ExperiencePerShift/types";
import {
  TGetSchedulesForHonoredPref,
  TScheduleDetails,
} from "~/features/Fairness/PreferencesHonored/types";
import { TGetSchedulesForWeekendShifts } from "~/features/Fairness/WeekendShifts/types";

import { ApiParams } from "../types";

export const getSchedulesForHonoredPref = async ({
  schedulesLimit,
  unitId,
}: ApiParams): Promise<TGetSchedulesForHonoredPref> => {
  const { data } = await axiosInstance.get<{ schedules: TScheduleDetails[] }>(
    "/fairness/preferences",
    {
      params: { schedulesLimit, unitId },
    },
  );

  return data;
};

export const getSchedulesForChargeShifts = async ({
  schedulesLimit,
  unitId,
}: ApiParams): Promise<TGetSchedulesForChargeShifts> => {
  const { data } = await axiosInstance.get<{ schedules: TScheduleDetails[] }>(
    "/fairness/charge-shifts",
    {
      params: { schedulesLimit, unitId },
    },
  );

  return data;
};

export const getSchedulesForWeekendShifts = async ({
  schedulesLimit,
  unitId,
}: ApiParams): Promise<TGetSchedulesForWeekendShifts> => {
  const { data } = await axiosInstance.get<{ schedules: TScheduleDetails[] }>(
    "/fairness/weekend-shifts",
    {
      params: { schedulesLimit, unitId },
    },
  );

  return data;
};

export const getSchedulesForHonoredBlockedDays = async ({
  schedulesLimit,
  unitId,
}: ApiParams): Promise<TGetSchedulesForHonoredPref> => {
  const { data } = await axiosInstance.get<{ schedules: TScheduleDetails[] }>(
    "/fairness/block-days-honored",
    {
      params: { schedulesLimit, unitId },
    },
  );

  return data;
};

export const getSchedulesForExperiencePerShift = async ({
  schedulesLimit,
  unitId,
}: ApiParams): Promise<TGetSchedulesForExperiencePerShift> => {
  const { data } = await axiosInstance.get<TGetSchedulesForExperiencePerShift>(
    "/fairness/experience-per-shift",
    {
      params: { schedulesLimit, unitId },
    },
  );

  return data;
};
