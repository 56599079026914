import { ISODateString, YyyyMmDd } from "@m7-health/shared-utils";

import { DeprecatedTShift } from "#/features/SchedulerGrid/types";
import {
  EStaffCategoryKey,
  EStaffTypeKey,
  seconds,
  TDateFilter,
  Uuid,
  TimeString,
  dateString,
} from "@/common/types";

import { APIElementResponse, ApiEmptyResponse, APIListResponse, SDTO } from "../_shared/types";
import { IOpenShiftRequest } from "../openShiftRequest";
import { ISchedule } from "../schedule";
import { IShiftIncentiveLevel } from "../shiftIncentiveLevel";
import { IShiftType } from "../shiftType";
import { StaffCategory } from "../staffCategory";
import { StaffShift } from "../staffShift";

export type IOpenShift = OpenShift.DTO;

export namespace OpenShift {
  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    date: YyyyMmDd;
    shiftType: IShiftType["key"];
    staffCategoryKey: StaffCategory.EKey;
    shiftCount: number;
    scheduleId: ISchedule["id"];
    note?: string;
    shiftIncentiveLevelId?: IShiftIncentiveLevel["id"] | null;
    openShiftRequests?: IOpenShiftRequest[];
    durationSeconds?: number;
    startDateTime?: ISODateString;
  }

  export namespace API {
    export const ROOT_PATH = "/open-shifts";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        staffCategoryKeys?: StaffCategory.EKey[];
        shiftTypeKeys?: IShiftType["key"][];
        shiftCount?: {
          min?: number; // Default: 1
          max?: number;
        };
        includePast?: boolean; // default: false
        unitIds?: Uuid[];
        date?: TDateFilter[];
        includeOpenShiftsRequests?: boolean;
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Create {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.scheduleId}`;

      export type QueryParams = {
        date: string;
        shiftCount: number;
        shiftType?: DeprecatedTShift;
        staffTypeKey?: EStaffTypeKey;
        scheduleId: string;
        staffCategoryKey?: EStaffCategoryKey;
        note?: string;
      };

      export type Response = APIElementResponse<IOpenShift["id"]>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.openShiftId}`;

      export type QueryParams = {
        openShiftId: IOpenShift["id"];
        shiftIncentiveLevelId?: IOpenShift["shiftIncentiveLevelId"];
        note?: IOpenShift["note"];
      };

      export type Response = APIElementResponse<IOpenShift["id"]>;
    }

    export namespace CreateBulk {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.scheduleId}/fill-gaps`;

      export type QueryParams = {
        scheduleId: ISchedule["id"];
      };

      export type Response = APIListResponse<IOpenShift["id"][]>;
    }

    export namespace ApproveRequest {
      export const PATH = (params: QueryParams) =>
        `${ROOT_PATH}/request/${params.requestId}/approve`;

      export type QueryParams = {
        requestId: IOpenShiftRequest["id"];
        approvalNote?: string;
        customStartTime?: TimeString | null | undefined;
        customDuration?: seconds | null | undefined;
        status?: StaffShift.EStatus | null | undefined;
        shiftIncentiveLevelId?: Uuid | null | undefined;
        shiftTypeKey?: IShiftType["key"] | null | undefined;
        shiftDate?: dateString | null | undefined;
      };

      export type Response = APIElementResponse<IOpenShift["id"]>;
    }

    export namespace RejectRequest {
      export const PATH = (params: QueryParams) =>
        `${ROOT_PATH}/request/${params.requestId}/reject`;

      export type QueryParams = {
        requestId: IOpenShiftRequest["id"];
      };

      export type Response = ApiEmptyResponse;
    }
  }
}
