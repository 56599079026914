import { useEffect, useMemo } from "react";

import { keyBy } from "lodash";

import { RosterActions } from "#/features/Roster/store";
import { TBulkCreateUsersUnitData } from "#/features/Roster/store/bulkCreateUsers";
import { IShiftType, useListUnitShiftTypeQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppDispatch, useM7SimpleContext, useStaffTypes } from "@/common/hooks";

const emptyShiftTypes: IShiftType[] = [];

export const useSetUnitData: () => TBulkCreateUsersUnitData = () => {
  const dispatch = useAppDispatch();
  const { timezone, currentUnitId } = useM7SimpleContext();
  const staffTypes = useStaffTypes();
  const { data: shiftTypes = emptyShiftTypes } = useListUnitShiftTypeQuery(
    { unitIds: [currentUnitId || NOT_EXISTING_UUID] },
    { skip: !currentUnitId },
  );

  useEffect(() => {
    if (shiftTypes)
      dispatch(
        RosterActions.setUnitData({
          shiftTypes: shiftTypes,
          indexedShiftTypes: keyBy(shiftTypes, "key"),
        }),
      );
    if (staffTypes)
      dispatch(
        RosterActions.setUnitData({
          staffTypes: staffTypes,
          indexedStaffTypes: keyBy(staffTypes, "key"),
        }),
      );
    if (timezone) dispatch(RosterActions.setUnitData({ timezone }));
  }, [dispatch, shiftTypes, staffTypes, timezone]);

  return useMemo(
    () => ({
      shiftTypes,
      staffTypes,
      indexedShiftTypes: keyBy(shiftTypes, "key"),
      indexedStaffTypes: keyBy(staffTypes, "key"),
      timezone,
    }),
    [shiftTypes, staffTypes, timezone],
  );
};
