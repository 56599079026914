import { useEffect, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";

import { useIsStaffApp } from "~/common/hooks/useIsStaffApp";
import { white } from "~/common/theming/colors";
import { emptySx } from "~/common/types";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { useAppFlags } from "@/common/hooks";

import CustomButton from "../TrackedComponents/Button";

import { ICustomModalProps } from "./types";

export const CustomModal = ({
  trackingLabel,
  sx = emptySx,
  modalHeaderText = "",
  isOpen,
  isLoading: isLoadingProp = false,
  onSecondaryBtnClick = undefined,
  onClose,
  onOpen,
  modalContent,
  onSubmit,
  onSubmitAsync,
  primaryBtnText = "Save Changes",
  primaryBtnColor,
  secondaryBtnText = "Cancel",
  primaryDisabled = false,
  closeDisabled = false,
  variant,
  variantText,
  variantTitle,
  withBlurBackground = false,
  customWidth,
  dangerousActionPhrase,
  classes,
  children,
}: ICustomModalProps) => {
  const dangerousIsDisabled = useAppFlags().disableTypeToConfirmModals;
  const isDangerousAction = Boolean(dangerousActionPhrase) && !dangerousIsDisabled;
  const [dangerousPhraseInput, setDangerousPhraseInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  modalContent ||= children;

  primaryDisabled ||= isDangerousAction && dangerousPhraseInput !== dangerousActionPhrase;
  closeDisabled ||= isLoading;

  const onCloseHandler = useMemo(() => {
    if (closeDisabled) {
      return null;
    } else {
      return (event?: React.SyntheticEvent) => {
        setDangerousPhraseInput("");
        (onClose ?? onSecondaryBtnClick)?.(event);
      };
    }
  }, [closeDisabled, onClose, onSecondaryBtnClick]);

  // Reset dangerous phrase input when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setDangerousPhraseInput("");
    }
  }, [isOpen]);

  useEffect(() => {
    const closeModalOnEscape = (event: KeyboardEvent) =>
      event.key === "Escape" && isOpen && onCloseHandler?.();
    document.addEventListener("keyup", closeModalOnEscape);

    return () => document.removeEventListener("keyup", closeModalOnEscape);
  }, [isOpen, onCloseHandler]);

  const isVariantModal = !!variant;

  const isStaffApp = useIsStaffApp();

  useEffect(() => {
    if (isOpen) {
      onOpen?.();
    }
  }, [isOpen, onOpen]);

  const modal = (
    <Modal
      classes={classes}
      open={isOpen}
      className="custom-modal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 1300,
        // Fix for safari z-index bug
        WebkitTransform: "translate3d(0, 0, 0)",
        transform: "translate3d(0, 0, 0)",
        ...(withBlurBackground ? { backdropFilter: "blur(5px)" } : {}),
        ...sx,
      }}
    >
      <Box
        className="modal-container"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: white,
          minWidth: isStaffApp ? customWidth || "300px" : customWidth || "500px",
          maxWidth: "100vw",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
          className={"title-and-close"}
        >
          <Grid item>
            <Typography variant="h6">{modalHeaderText}</Typography>
          </Grid>
          <Grid item>
            {onCloseHandler ? (
              <IconButton onClick={onCloseHandler} tabIndex={0} sx={{ cursor: "pointer" }}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
        <Box className="modal-content-container">
          {isVariantModal ? (
            <Grid container flexDirection="column" sx={{ marginTop: 3 }}>
              {variantTitle && (
                <Grid item mb={3}>
                  <Typography fontSize="0.875rem">{variantTitle}</Typography>
                </Grid>
              )}
              {variantText && (
                <Grid item>
                  <Alert severity={variant}>{variantText}</Alert>
                </Grid>
              )}
              {modalContent && <Grid item>{modalContent}</Grid>}
            </Grid>
          ) : (
            modalContent
          )}
        </Box>
        {isDangerousAction && (
          <Box>
            <Typography mt={3} variant="body2" fontSize="0.875rem">
              Please type <b>{dangerousActionPhrase}</b> to {primaryBtnText.toLowerCase()}.
            </Typography>
            <TextField
              size="small"
              sx={{ mt: 1 }}
              fullWidth
              value={dangerousPhraseInput}
              onChange={(event) => setDangerousPhraseInput(event.target.value)}
            />
          </Box>
        )}
        {(onSubmit || onSubmitAsync || onSecondaryBtnClick) && (
          <Grid container className={"actions"} mt={isVariantModal ? 4 : 5}>
            {(onSubmit || onSubmitAsync) && (
              <Box mr={2}>
                {onSubmit || onSubmitAsync ? (
                  <CustomButton
                    disabled={primaryDisabled}
                    isLoading={isLoading || isLoadingProp}
                    label={primaryBtnText}
                    onClick={onSubmit}
                    onClickAsync={async (event) => {
                      setIsLoading(true);
                      await onSubmitAsync?.(event);
                      setIsLoading(false);
                    }}
                    type="submit"
                    color={primaryBtnColor}
                  />
                ) : null}
              </Box>
            )}
            {onSecondaryBtnClick ? (
              <CustomButton
                isLoading={isLoading || isLoadingProp}
                label={secondaryBtnText}
                variant="text"
                onClick={onSecondaryBtnClick}
              />
            ) : null}
          </Grid>
        )}
      </Box>
    </Modal>
  );

  return trackingLabel ? (
    <MixpanelProvider
      properties={{
        [Mxp.Property.layout.component]: `modal:${trackingLabel}`,
      }}
    >
      {modal}
    </MixpanelProvider>
  ) : (
    modal
  );
};
