import { useEffect, useState } from "react";

import { ISODateString, User } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { TextField } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { Dayjs } from "~/common/packages/dayjs";

import { useInvalidateQuery, useListStaffDetailsQuery } from "@/api";
import { CustomDatePicker, CustomSelect } from "@/common/components";
import { ICustomSelectItem } from "@/common/components/TrackedComponents/Select/types";
import { useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";

import { changeStaffStatus, submitReasonAndNoteForSuspension, suspendAccount } from "../../api";
import { setIsSuspendedModalOpen } from "../../store";
import { EStaffStatus } from "../../types";

const SuspendUserModal = () => {
  /** HIGH-LEVEL */
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateQuery();
  const { showSuccess } = useToast();
  const timezone = useCurrentTimezone();

  const { id, name, skipPostDate } = useAppSelector(
    (state) => state.roster.suspendAccountModal.data,
  );
  const { isOpen } = useAppSelector((state) => state.roster.suspendAccountModal);

  /** STATES */
  const [statusChangeDate, setStatusChangeDate] = useState<Dayjs | undefined>(undefined);
  useEffect(() => {
    if (!timezone || statusChangeDate) return;
    setStatusChangeDate(localDayJs().tz(timezone));
  }, [timezone, statusChangeDate]);

  const [reasonForSuspension, setReasonForSuspension] = useState<
    User.EReasonForSuspension | undefined
  >(undefined);
  const [noteForSuspension, setNoteForSuspension] = useState<string>("");
  const reasonForSuspensionItems: ICustomSelectItem<User.EReasonForSuspension>[] = Object.values(
    User.EReasonForSuspension,
  ).map((reason) => ({
    value: reason,
    label: reason,
    item: reason,
  }));

  const resetState = () => {
    setStatusChangeDate(localDayJs().tz(timezone));
  };

  const onSettled = () => {
    resetState();
    void queryClient.invalidateQueries({ queryKey: ["roster"] });
    void invalidateQueries(useListStaffDetailsQuery);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: suspendAccount,
    onSuccess: () => {
      dispatch(setIsSuspendedModalOpen(false));
      showSuccess("Suspended user successfully");
    },
    onError: handleErrors,
    onSettled,
  });
  const { mutate: mutateDetailsAndSuspend, isPending: detailsAndSuspendIsLoading } = useMutation({
    mutationFn: changeStaffStatus,
    onSuccess: () => {
      dispatch(setIsSuspendedModalOpen(false));
      showSuccess("Planned suspension date updated successfully");
    },
    onError: handleErrors,
    onSettled,
  });
  const { mutate: mutateDetails, isPending: detailsIsLoading } = useMutation({
    mutationFn: submitReasonAndNoteForSuspension,
    onError: handleErrors,
    onSettled,
  });

  const confirmSuspension = () => {
    if (skipPostDate) {
      // if skipPostDate is true, we don't need to do anything fancy
      mutateDetails({
        staffId: id,
        reasonForSuspension,
        noteForSuspension,
      });
      mutate({ id });
    } else if (!statusChangeDate) {
      // else if statusChangeDate is not set, don't do anything (should never happen, should always be set)
      return;
    } else if (statusChangeDate.isAfter(localDayJs().tz(timezone), "day")) {
      // Else if we know suspension is post-dated, we need to update staff details
      mutateDetailsAndSuspend({
        staffId: id,
        status: EStaffStatus.Active,
        postDateStatusUpdateDate: statusChangeDate.toISOString() as ISODateString,
        postDateStatusUpdateType: "suspended",
        reasonForSuspension,
        noteForSuspension,
      });
    } else {
      // else, we can just suspend the user
      mutateDetails({
        staffId: id,
        reasonForSuspension,
        noteForSuspension,
      });
      mutate({ id });
    }
  };

  const handleSecondaryBtnClick = () => {
    resetState();
    dispatch(setIsSuspendedModalOpen(false));
  };

  const variantText = (
    <>
      This user will be removed from rosters. All past data related to this user will be saved and
      the user can be restored, if needed.
      <br />
      <br />
      If the user is a staff, all of the staff's future shifts and preferences after{" "}
      {skipPostDate ? "today" : "the specified date"} will be removed from current and future
      schedules. If the user is both a staff and scheduler, this action will suspend access for both
      roles.
    </>
  );

  const variantTitle = (
    <>
      Are you sure you want to suspend <strong>{name}</strong>?
      <br />
      <br />
      {statusChangeDate && !skipPostDate && (
        <CustomDatePicker
          disablePast
          name="statusChangeDate"
          label="Effective suspension date:"
          value={statusChangeDate}
          onChange={(newDate) => newDate && setStatusChangeDate(newDate)}
          timezone={timezone}
          sx={{ mb: 2 }}
        />
      )}
      <CustomSelect<User.EReasonForSuspension>
        name="reasonForSuspension"
        label="Reason for suspension* (Required)"
        items={reasonForSuspensionItems}
        value={reasonForSuspension || ""}
        onChange={(event) =>
          setReasonForSuspension(event.target.value as User.EReasonForSuspension)
        }
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        multiline
        onChange={(event) => {
          setNoteForSuspension(event.target.value);
        }}
        placeholder="Add a note explaining the reason for the suspension"
        minRows={3}
      />
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      primaryBtnText="Suspend"
      modalHeaderText="Suspend Account"
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmSuspension}
      primaryDisabled={
        isLoading ||
        detailsAndSuspendIsLoading ||
        detailsIsLoading ||
        noteForSuspension === "" ||
        !reasonForSuspension
      }
      variant="warning"
      variantText={variantText}
      variantTitle={variantTitle}
    />
  );
};

export default SuspendUserModal;
