import { filter, lowerCase, sortBy } from "lodash";

import { axiosInstance } from "~/common/packages/httpClient";
import { Uuid } from "~/common/types";

import { IAttribute, IUnit } from "@/api";

import {
  ICreateUnit,
  IEditUnit,
  IFacility,
  IGetFacilities,
  IGetShifts,
  IGetStaffAttributesForUnit,
  IGetStaffCategories,
  IGetStaffTypeCategoryNameToKey,
  IGetStaffTypes,
  IGetStaffTypesAndCategories,
  IGetUnits,
  IShift,
  IUnitBasic,
  IUnitCustomSelect,
} from "./types";

const UNITS_ENDPOINT = "/units";
const ATTRIBUTES_ENDPOINT = "/attribute";
const STAFF_TYPE_NAMES_ENDPOINT = "/staff-type";

export const createUnitApi = async (body: ICreateUnit) => {
  const { facilityId } = body;
  const expectedBody = { ...body, facility: { id: facilityId } };
  const response = await axiosInstance.post<{ id: string }>(UNITS_ENDPOINT, expectedBody);

  const { data } = response;

  return data;
};

export const editUnitApi = async (requestObj: IEditUnit) => {
  const { body, unitId } = requestObj;
  const { facilityId } = body;
  const expectedBody = { ...body, facility: { id: facilityId } };

  const { data } = await axiosInstance.put<IUnit>(`${UNITS_ENDPOINT}/${unitId}`, expectedBody);

  return data;
};

export const getFacilitiesApi = async (): Promise<IFacility[]> => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetFacilities>("/facility");

  const dataDto = data.map(({ id, name, configuration }) => ({
    id,
    name,
    configuration,
  }));

  return dataDto;
};

export const listUnitsApi = async (): Promise<IUnitBasic[]> => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetUnits>(UNITS_ENDPOINT);

  return data;
};

/** @deprecated, use listUnitsApi and wrapp it into a dedicated serializer for dedicated usage */
export const getUnitsApiForSelect = async (): Promise<IUnitCustomSelect[]> => {
  try {
    const {
      data: { data },
    } = await axiosInstance.get<IGetUnits>(UNITS_ENDPOINT);

    const dataDTO = data.map((element) => ({
      ...element,
      label: element.name,
      value: element.id,
    }));
    return sortBy(dataDTO, [(item) => lowerCase(item.label)]);
  } catch (error) {
    return [];
  }
};

export const getStaffAttributesForUnitCustomSelectApi = async (unitId: string) => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetStaffAttributesForUnit>(`${ATTRIBUTES_ENDPOINT}/staff/${unitId}`);

  return sortBy(data, [(item) => lowerCase(item.key)]);
};

export const getStaffAttributesForUnitApi = async (unitId: string): Promise<IAttribute[]> => {
  const {
    data: { data },
  } = await axiosInstance.get<{ data: IAttribute[] }>(`${ATTRIBUTES_ENDPOINT}/staff/${unitId}`);
  return data;
};

export const getAllStaffAttributes = async () => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetStaffAttributesForUnit>(`${ATTRIBUTES_ENDPOINT}/staff`);
  const sortedDataDTO = sortBy(data, [(item) => lowerCase(item.key)]);

  return sortedDataDTO;
};
export const getShiftsApi = async (): Promise<Record<string, IShift>> => {
  try {
    const {
      data: { data },
    } = await axiosInstance.get<IGetShifts>("/shift-types");
    const dataDTO = sortBy(data, [(item) => lowerCase(item.name)]);
    const shiftsObject: Record<IShift["key"], IShift> = dataDTO.reduce(
      (acc, shift) => {
        acc[shift.key] = shift;
        return acc;
      },
      {} as Record<string, IShift>,
    );
    return shiftsObject;
  } catch (error) {
    return {} as Record<string, IShift>;
  }
};

export const getShiftsByUnitApi = async (unitIds: Uuid[]): Promise<Record<string, IShift>> => {
  const filteredUnitIds = filter(unitIds || []);
  const {
    data: { data },
  } = await axiosInstance.get<IGetShifts>("/unit-shift-type", {
    params: {
      ...(filteredUnitIds.length && {
        unitIds: filteredUnitIds,
      }),
    },
  });
  const dataDTO = sortBy(data, [(item) => lowerCase(item.name)]);
  // this makes sure that if we have multiple shifts with the same key, we only get the last one
  // only using the key for filtering open shifts, so this is fine, will only affect how things look
  const shiftsObject: Record<IShift["key"], IShift> = dataDTO.reduce(
    (acc, shift) => {
      acc[shift.key] = shift;
      return acc;
    },
    {} as Record<string, IShift>,
  );
  return shiftsObject;
};

/** @deprecated use getShiftsByScheduleApi from api/shift-type instead */
export const getShiftsByScheduleApi = async (
  scheduleId: string,
): Promise<Record<string, IShift>> => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetShifts>("/schedule-shift-type", {
    params: {
      ...(scheduleId && {
        scheduleIds: [scheduleId],
      }),
    },
  });
  const dataDTO = sortBy(data, [(item) => lowerCase(item.name)]);
  const shiftsObject: Record<IShift["key"], IShift> = dataDTO.reduce(
    (acc, shift) => {
      acc[shift.key] = shift;
      return acc;
    },
    {} as Record<string, IShift>,
  );
  return shiftsObject;
};

export const getStaffTypeNamesApi = async () => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetStaffTypes>(STAFF_TYPE_NAMES_ENDPOINT);
  const dataDTO = data.map(({ key, name }) => ({
    label: name,
    value: name,
    key: key,
  }));

  const orderLookup: { [key: string]: number } = {
    houseSupervisor: 0,
    rn: 1,
    lvn: 2,
    lpn: 3,
    cna: 4,
    nurseExtern: 5,
    monitorTech: 6,
  };

  const dataDTOCustomOrdering = dataDTO.sort((x, y) => {
    return x.key in orderLookup && y.key in orderLookup
      ? (orderLookup[x.key] || 0) - (orderLookup[y.key] || 0)
      : 7;
  });

  return dataDTOCustomOrdering;
};

export const getStaffCategoriesTargetLevelApi = async () => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetStaffCategories>("/staff-category/target-level");

  return data;
};

export const getStaffTypesAndCategoriesApi = async () => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetStaffTypesAndCategories>("/staff-type/associated-categories");
  return data;
};

export const getStaffTypeCategoryNameToKey = async () => {
  // gets object aka hash map of staff type / category name to key
  const {
    data: { data },
  } = await axiosInstance.get<IGetStaffTypeCategoryNameToKey>("/staff-type/names-to-keys");

  return data;
};
