import { YyyyMmDd } from "@m7-health/shared-utils";

import { localDayJs } from "~/common/packages/dayjs";

import { EDailyReportViewType, IDailyReportState } from "../types";

export const initialState: IDailyReportState = {
  date: localDayJs().format("YYYY-MM-DD") as YyyyMmDd,
  updatesToSave: {},
  filters: { viewType: EDailyReportViewType.list, shiftType: [] },
  isAddLabelModelOpen: false,
  unsavedChangesModal: {
    isOpen: false,
    handleDiscardChanges: () => {
      return;
    },
  },
  staffNotes: undefined,
  readOnlyNotesFromStaff: undefined,
  isEdited: false,
};
