import "./SideBar.scss";

import { memo, useCallback, useEffect, useMemo } from "react";

import { EUnitPermissionAreas, getTzDate } from "@m7-health/shared-utils";
import { isEqual, sortBy } from "lodash";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Typography } from "@mui/material";

import { IStaffDetails } from "~/api";
import CustomButton from "~/common/components/TrackedComponents/Button";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useCurrentFacilityId } from "~/common/hooks/useCurrentFacilityId";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { lightGray, mediumGray } from "~/common/theming/colors";
import { isOnMobile } from "~/common/utils/isOnMobile";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useFilterBy,
} from "@/common/hooks";

import { HouseView } from "..";
import { houseViewStore } from "../../store";

import { EditShiftsV2 } from "./states/EditShiftsV2";
import { FloatToUnitV2 } from "./states/FloatToUnitV2";
import { UpdateStatus } from "./states/UpdateStatus";
import { THouseViewSideBar } from "./types";

import { HouseViewSideBar } from ".";

const SideBarStates = HouseViewSideBar.States;

const componentByAction = () =>
  ({
    floating: FloatToUnitV2,
    assignAttributes: SideBarStates.AssignAttributes,
    findStaffToWork: SideBarStates.FindStaffToWork,
    editShifts: EditShiftsV2,
    updateStatus: UpdateStatus,
  }) as const;
const labelByAction = () =>
  ({
    floating: (unitName: string) => `Float Staff to ${unitName}`,
    assignAttributes: (_unitName: string) => "Assign Positions",
    findStaffToWork: (_unitName: string) => "Find Staff to Work",
    editShifts: (_unitName: string, staffDetails?: IStaffDetails) =>
      (staffDetails
        ? `Edit ${staffDetails.user.lastName || ""} ${staffDetails.user.firstName || ""}'s`
        : "Saving") + " Schedule",
    updateStatus: (_unitName: string) => "Add Status Update",
  }) as const;

const SideBar = (props: Omit<THouseViewSideBar, "selectedUnit">) => {
  /** HIGH-LEVEL STATE */
  const dispatch = useAppDispatch();
  const { showInfo } = useToast();

  /** USER STATE */
  const mobileView = isOnMobile();
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);

  const {
    selectedUnitId,
    selectedDateForData,
    currentAction,
    isSidebarActionDirty,
    selectedStaffDetails,
    bannerIsOpen,
    staffDatesMetadata,
    isMultiWeekViewSelected,
  } = useAppSelector((state) => {
    const selectedStaffId = state.houseView.editShifts.staffId;
    return {
      bannerIsOpen: state.user.bannerIsOpen,
      selectedUnitId: state.houseView.pageFilters.selectedUnitId,
      selectedDateForData: state.houseView.pageFilters.selectedDateForData,
      currentAction: state.houseView.sidebarCurrentAction.inProgress,
      isSidebarActionDirty: state.houseView.sidebarCurrentAction.isDirty,
      selectedStaffDetails: selectedStaffId
        ? state.houseView.pageData.staffDetails[selectedStaffId]
        : undefined,
      staffDatesMetadata: state.houseView.pageData.metadataByStaffId,
      isMultiWeekViewSelected: state.houseView.pageFilters.isMultiWeekView,
    };
  }, isEqual);
  const timezone = useCurrentTimezone(selectedUnitId);

  const selectedUnit = useMemo(
    () => (selectedUnitId && props.units?.[selectedUnitId]) || null,
    [selectedUnitId, props.units],
  );
  const aUnitIsSelected = !!selectedUnitId;

  const facilityId = useCurrentFacilityId() || NOT_EXISTING_UUID;
  const unFilteredUnits = useAppConfigQuery().data?.accessibleUnits;
  const unOrderedUnits = useFilterBy(unFilteredUnits, (unit) => unit.facilityId === facilityId, [
    facilityId,
  ]);

  const ComponentToDisplay = useMemo(() => {
    if (currentAction) return componentByAction()[currentAction];

    if (aUnitIsSelected) return SideBarStates.SelectedUnit;

    return null;
  }, [currentAction, aUnitIsSelected]);

  const cancelAction = useCallback(() => {
    if (isSidebarActionDirty) {
      showInfo("You have unsaved changes. Please save or cancel first.");
      return;
    }
    dispatch(houseViewStore.state.endAction());
  }, [dispatch, isSidebarActionDirty, showInfo]);

  const actionIsInProgress = !!currentAction;
  const selectedUnitName = selectedUnit?.name || "";
  const editOrView = userIsKiosk || !canManage ? "View" : "Edit";
  const topBarContent = currentAction
    ? labelByAction()[currentAction](selectedUnitName, selectedStaffDetails)
    : `${editOrView} unit: ${selectedUnitName}, ${getTzDate(selectedDateForData, timezone).toFormat("LL/dd/yyyy")}`;

  // Select first unit
  useEffect(() => {
    if (isOnMobile()) return;
    const orderedUnits = sortBy(unOrderedUnits || [], "sortPosition", "name");
    if (!orderedUnits?.length) return;
    if (!selectedUnitId || !orderedUnits.find(({ id }) => id === selectedUnitId)) {
      const firstUnitId = orderedUnits[0]?.id;
      if (firstUnitId) {
        dispatch(houseViewStore.state.selectUnit(firstUnitId));
      }
    }
  }, [selectedUnitId, unOrderedUnits, dispatch, canManage]);

  return (
    <MixpanelProvider
      properties={{
        [Mxp.Property.layout.section]: `sidebar${isMultiWeekViewSelected ? "-multi-week" : ""}`,
        [Mxp.Property.layout.subSection]: currentAction || "__none__",
      }}
    >
      <Box
        className={`house-view-sidebar ${currentAction || "selected-unit"}`}
        position={"relative"}
        width={HouseView.SIDEBAR_WIDTH}
        height={`calc(100vh - ${bannerIsOpen ? "160px" : "110px"})`}
        sx={{
          overflowY: "auto",
          border: `1px solid ${mediumGray}`,
          borderRadius: "5px",
          marginTop: "8px",
          marginRight: "8px",
          marginLeft: "8px",
          minWidth: HouseView.SIDEBAR_WIDTH,
        }}
      >
        {ComponentToDisplay && selectedUnit ? (
          <Box
            width="calc(100% - 16px)"
            mx={1}
            height={"100%"}
            display="flex"
            flexDirection={"column"}
          >
            <Box
              sx={{
                background: lightGray,
                p: 1,
                position: "relative",
                mb: "16px",
                right: "8px",
                width: "calc(100%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {actionIsInProgress && (
                <CustomButton
                  trackingLabel="cancel action"
                  iconOnly
                  onClick={cancelAction}
                  startIcon={<KeyboardArrowLeft />}
                />
              )}
              {!actionIsInProgress && selectedUnit && mobileView && (
                <CustomButton
                  trackingLabel="back-to-unit-list"
                  iconOnly
                  startIcon={<KeyboardArrowLeft />}
                  onClick={() => dispatch(houseViewStore.state.selectUnit(null))}
                />
              )}
              <Typography
                width={"fit-content"}
                fontWeight={500}
                sx={
                  actionIsInProgress
                    ? {
                        position: "relative",
                        left: "35px",
                      }
                    : {}
                }
                children={topBarContent}
              />
            </Box>
            <HouseViewSideBar.Helpers.TopActions />
            <ComponentToDisplay
              {...props}
              staffDatesMetadata={staffDatesMetadata}
              selectedUnit={selectedUnit}
              key={"house-view-sidebar-" + (selectedUnitId || "") + (selectedDateForData || "")}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </MixpanelProvider>
  );
};
export const __HouseViewSideBar = memo(SideBar);
