import { ISODateString } from "@m7-health/shared-utils";

import { APIListResponse, SDTO } from "../_shared/types";
import { IOpenShift } from "../openShift/types";
import { IUser } from "../user";

export type IOpenShiftRequest = OpenShiftRequest.DTO;

export namespace OpenShiftRequest {
  export enum EStatus {
    pending = "pending",
    accepted = "accepted",
    rejected = "rejected",
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    openShiftId: IOpenShift["id"];
    status: EStatus;
    decidedAt: ISODateString | null;
    deciderId: IUser["id"] | null;
    requesterId: IUser["id"];
    staffNote?: string;
    createdById: IUser["id"];
  }

  export namespace API {
    export const ROOT_PATH = "/open-shift-request";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        requesterIds?: IUser["id"][];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
