import { KeyBy, PreferenceRequirementRuleSet } from "@m7-health/shared-utils";
import { get } from "lodash";

import { Divider, Typography } from "@mui/material";

import { IPreferenceRequirementResults, IPreferenceRequirementRule, IShiftType } from "@/api";

import CustomModal from "../../../Modal";
import { ShiftV2 } from "../../../Shift/ShiftV2";

type AvailableFrequencies = Exclude<
  PreferenceRequirementRuleSet.EFrequencyStep,
  PreferenceRequirementRuleSet.EFrequencyStep.schedule
>;

export const RequirementResultModal = ({
  result,
  rule,
  onClose,
  shiftTypes,
}: {
  result?: IPreferenceRequirementResults[number] | null;
  rule?: IPreferenceRequirementRule | null;
  onClose: () => void;
  shiftTypes: KeyBy<IShiftType, "key">;
}) => {
  if (!result || !rule) return null;

  const rangeStatus = result.rangeStatus || "undefined";
  const headers = copy.header[rangeStatus]();
  const ruleCopy = get(copy.body, rule.frequencyStep)?.[rule.targetType]?.[rangeStatus]?.({
    rule,
    result,
  });

  if (!ruleCopy) return null;

  return (
    <CustomModal
      modalHeaderText={headers}
      isOpen={true}
      onClose={onClose}
      onSecondaryBtnClick={onClose}
      secondaryBtnText="Close"
      modalContent={
        <>
          <Typography children={ruleCopy} />
          <Divider sx={{ m: 2 }} />
          <Typography children="Qualifying Shift(s): " />
          {rule.targetShiftTypeKeys.map((key) => {
            const shiftType = shiftTypes[key];
            // if not in the list, soft deleted shift type
            if (!shiftType) return null;

            return <ShiftV2 sx={{ m: 1 }} key={key} shiftType={shiftType} />;
          })}
        </>
      }
      withBlurBackground
      sx={{
        ".modal-container": { padding: "18px", borderRadius: "5px" },
        ".title-and-close": { paddingBottom: "15px" },
      }}
    />
  );
};

/** Private helper
 *
 * Given a frequency, returns the true start/end of the incomplete period
 * When starting from the first period of the schedule, the end of the period is the end of the first week
 * When starting from the last period of the schedule, the start of the period is the start of the last week
 */
const undefinedCheckPeriodFromTo = (
  frequency: IPreferenceRequirementResults[number]["frequency"],
  { periodWeekSize }: { periodWeekSize: number },
) => {
  const { from, to } = frequency.range;
  const firstPeriodOfSchedule = frequency.value === "0";
  const offset = periodWeekSize - 1;

  if (firstPeriodOfSchedule) {
    const realTo = to.endOf("week");
    const realFrom = from.addInTz(-offset, "week").startOf("week");
    return { realFrom, realTo };
  }

  const realFrom = from.startOf("week");
  const realTo = to.addInTz(offset, "week").endOf("week");
  return { realFrom, realTo };
};

const copy = {
  header: {
    within: () => "✅ Requirements Met",
    under: () => "❌ Requirements Not Met",
    over: () => "❌ Requirements Exceeded",
    undefined: () => "Requirements",
  } satisfies TCopyLeaf,
  body: {
    [PreferenceRequirementRuleSet.EFrequencyStep.weekly]: {
      [PreferenceRequirementRuleSet.ETargetType.hours]: {
        within: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `week of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift hours required the ${formattedFromTo}.`;
        },
        over: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `week of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Maximum of ${rule.frequencyValueMax} shift hours required the ${formattedFromTo}.`;
        },
        under: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `week of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift hours required the ${formattedFromTo}.`;
        },
        undefined: ({ result }) => {
          const { realFrom, realTo } = undefinedCheckPeriodFromTo(result.frequency, {
            periodWeekSize: 1,
          });
          return `Requirements validity cannot be determined as schedule doesn't contain the full week of ${realFrom.format("MM/DD")}-${realTo.format("MM/DD")}.`;
        },
      },
      [PreferenceRequirementRuleSet.ETargetType.shiftCount]: {
        within: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `week of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift(s) required the ${formattedFromTo}.`;
        },
        over: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `week of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Maximum of ${rule.frequencyValueMax} shift(s) required the ${formattedFromTo}.`;
        },
        under: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `week of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift(s) required the ${formattedFromTo}.`;
        },
        undefined: ({ result }) => {
          const { realFrom, realTo } = undefinedCheckPeriodFromTo(result.frequency, {
            periodWeekSize: 1,
          });
          return `Requirements validity cannot be determined as schedule doesn't contain the full week of ${realFrom.format("MM/DD")}-${realTo.format("MM/DD")}.`;
        },
      },
    },
    [PreferenceRequirementRuleSet.EFrequencyStep.payPeriod]: {
      [PreferenceRequirementRuleSet.ETargetType.hours]: {
        within: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `pay period of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift hours required the ${formattedFromTo}.`;
        },
        over: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `pay period of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Maximum of ${rule.frequencyValueMax} shift hours required the ${formattedFromTo}.`;
        },
        under: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `pay period of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift hours required the ${formattedFromTo}.`;
        },
        undefined: ({ result }) => {
          const { realFrom, realTo } = undefinedCheckPeriodFromTo(result.frequency, {
            periodWeekSize: 2,
          });
          return `Requirements validity cannot be determined as schedule doesn't contain the full pay period of ${realFrom.format("MM/DD")}-${realTo.format("MM/DD")}.`;
        },
      },
      [PreferenceRequirementRuleSet.ETargetType.shiftCount]: {
        within: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `pay period of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift(s) required the ${formattedFromTo}.`;
        },
        over: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `pay period of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Maximum of ${rule.frequencyValueMax} shift(s) required the ${formattedFromTo}.`;
        },
        under: ({ rule, result }) => {
          const { from, to } = result.frequency.range;
          const formattedFromTo = `pay period of ${from.format("MM/DD")}-${to.format("MM/DD")}`;
          return `Minimum of ${rule.frequencyValueMin} shift(s) required the ${formattedFromTo}.`;
        },
        undefined: ({ result }) => {
          const { realFrom, realTo } = undefinedCheckPeriodFromTo(result.frequency, {
            periodWeekSize: 2,
          });
          return `Requirements validity cannot be determined as schedule doesn't contain the full pay period of ${realFrom.format("MM/DD")}-${realTo.format("MM/DD")}.`;
        },
      },
    },
  } satisfies Record<
    AvailableFrequencies,
    Record<PreferenceRequirementRuleSet.ETargetType, TCopyLeaf>
  >,
};

type TCopyFn = (args: {
  rule: IPreferenceRequirementRule;
  result: IPreferenceRequirementResults[number];
}) => string;
type TCopyLeaf = {
  within: TCopyFn;
  under: TCopyFn;
  over: TCopyFn;
  undefined: TCopyFn;
};
